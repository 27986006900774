import React from "react";
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faMapMarker, faCross, faBook, faTag } from '@fortawesome/free-solid-svg-icons'


function getIcon(props) {
  switch (props.topic.type) {
    case 'person':
      return (<FontAwesomeIcon icon={faMale} className="mr-1"></FontAwesomeIcon>)
    case 'place':
      return (<FontAwesomeIcon icon={faMapMarker} className="mr-1"></FontAwesomeIcon>)
    case 'deity':
      return (<FontAwesomeIcon icon={faCross} className="mr-1"></FontAwesomeIcon>)
    case 'book':
      return (<FontAwesomeIcon icon={faBook} className="mr-1"></FontAwesomeIcon>)
    case 'topic':
    default:
      return (<FontAwesomeIcon icon={faTag} className="mr-1"></FontAwesomeIcon>)
  }
}

function getColor(props) {
  switch (props.topic.type) {
    case 'person':
      return 'text-blue-700 bg-blue-100 hover:bg-blue-200 border border-solid border-blue-300 hover:border-blue-400'
    case 'place':
      return 'text-purple-700 bg-purple-100 hover:bg-purple-200 border border-solid border-purple-300 hover:border-purple-400'
    case 'deity':
      return 'text-green-700 bg-green-100 hover:bg-green-200 border border-solid border-green-300 hover:border-green-400'
    case 'book':
      return 'text-black bg-yellow-100 hover:bg-yellow-200 border border-solid border-yellow-300 hover:border-yellow-400'
    case 'topic':
    default:
      return 'text-black bg-gray-100 hover:bg-gray-200 border border-solid border-gray-300 hover:border-gray-400'
  }
}

/**
 * Render a block representing a single topic.
 * @param {object} props Properties object containing a topic field with a topic ID.
 * @returns React element containing a post.
 */
function TopicChip(props) {  
  const icon = getIcon(props)
  const color = getColor(props)

  return (
    <Link to={`/topic/${props.topic.topicId}`} itemProp="url">
      <div className={`flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full hover:shadow-md ${color}`}>
        <div slot="avatar">
          {icon}
        </div>
        <div className="text-sm font-normal leading-none max-w-full flex-initial">
          {props.topic.name}
        </div>
        {
          props.count ?
            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{props.count}</span>
            : ''
        }
      </div>
    </Link>
  );
}

export default TopicChip;