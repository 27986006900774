import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostMiniCard from '../components/post-mini-card'
// import SeriesMiniCard from "../components/series-mini-card"
import TopicChip from "../components/topic-chip"

import { RefTagger } from 'react-reftagger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const TopicTemplate = ({ data, location, pageContext }) => {
  const topic = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const nextTopic = data.next
  const previousTopic = data.previous
  const postsByTopic = data.lessons.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={topic.frontmatter.name}
        description={topic.frontmatter.description || topic.excerpt}
      />
      <RefTagger bibleVersion={'ESV'} />
      
      <article
      itemScope
      itemType="http://schema.org/Article"
      className="mb-20"
    >
        <header>
          <h1 className="font-headline text-4xl color-blue-800 font-bold mt-4">
            <span itemProp="headline">{topic.frontmatter.name}</span>
          </h1>
          <p>{topic.frontmatter.type}</p>
        </header>
        
        <section>
          <div
            dangerouslySetInnerHTML={{
              __html: topic.html,
            }}
            itemProp="description"
            className="post-content mt-4"
          />          
        </section>

        <section>
            <h2>See also:</h2>
            {topic.frontmatter.relatedTopics && topic.frontmatter.relatedTopics.length > 0 ? 
              <div className="flex flex-wrap justify-center">
                {topic.frontmatter.relatedTopics.map(relatedTopic => {
                  return (
                    <TopicChip topic={relatedTopic.frontmatter} />
                  )
                })}
              </div>
              :
              <p className="text-italic">None.</p>
            }
        </section>

        {/* {topic.frontmatter.recommendedSeries ? 
          (
            <section>
              <h2 className="mt-4">
                <FontAwesomeIcon icon={faStar}/> Recommended series:
              
                <span className="align-middle inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{topic.frontmatter.recommendedSeries.length}</span>
              </h2>
            { 
              topic.frontmatter.recommendedSeries.map(series => {
                return (
                  <SeriesMiniCard series={series} />
                )
              })
            }
            </section>
          )
          : 
          (
            <section>
              <h2 className="mt-4 mb-1">
              Recommended series:
            </h2>
            <p className="text-italic">None</p></section>
          )
        } */}

        {topic.frontmatter.recommendedLessons ? 
          (
            <section>
              <h2 className="mt-4">
                <FontAwesomeIcon icon={faStar}/> Recommended lessons:
              
                <span className="align-middle inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{topic.frontmatter.recommendedLessons.length}</span>
              </h2>
            { 
              topic.frontmatter.recommendedLessons.map(post => {
                return (
                  <PostMiniCard post={post} />
                )
              })
            }
            </section>
          )
          : 
          (
            <section>
              <h2 className="mt-4 mb-1">
              Recommended lessons:
            </h2>
            <p className="text-italic">None</p></section>
          )
        }

        <section>
          <h2 className="mt-4">
            Related lessons:
            <span className="align-middle inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{postsByTopic.length}</span>
          </h2>
          {
            (postsByTopic.map(post => {
              return (
                <PostMiniCard post={post} />
              )
            }))
          }
          
        </section>
      </article>
      
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previousTopic && (
              <Link to={previousTopic.fields.slug} rel="prev">
                ← {previousTopic.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {nextTopic && (
              <Link to={nextTopic.fields.slug} rel="next">
                {nextTopic.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default TopicTemplate

export const pageQuery = graphql`
  query TopicBySlug(
    $id: String!
    $previousTopicId: String
    $nextTopicId: String
    $topicId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        type
        recommendedLessons {
          frontmatter {
            postId
            postType
            date(formatString: "MMMM DD, YYYY")
            lessonDate(formatString: "MMMM DD, YYYY")
            title
            description
            audio
            video
            
            series {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            subSeries {
              frontmatter {
                name
                seriesId
              }
            }
            venue {
              frontmatter {
                name
                venueId
              }
            }
            presenters {
              frontmatter {
                name
                presenterId
              }
            }
            topics {
              frontmatter {
                name
                type
                topicId
              }
            }
            venue {
              frontmatter {
                name
              }
            }     
          }
        }
        relatedTopics {
          frontmatter {
            topicId
            name
            type
          }
        }
        recommendedSeries {
          frontmatter {
            seriesId
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }     
          }
        }
      }
    
    lessons: allMarkdownRemark(
      sort: { fields: [frontmatter___postDate], order: DESC }
      filter: {
        frontmatter: {
          topics: {
            elemMatch: {
              frontmatter: {
                topicId: {eq: $topicId}
              }
            }
          },
          docType: {eq: "post"}
        }
      }
    ) {
      totalCount
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          lessonDate(formatString: "MMMM DD, YYYY")
          title
          postId
          postType
          series {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          presenters {
            frontmatter {
              name
              presenterId
            }
          }
          venue {
            frontmatter {
              name
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousTopicId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextTopicId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
